import { lazy, Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HourglassComponent from "./components/UI/HourglassComponent/HourglassComponent";
import { ROUTE_EVENT_BOOKING_FEEDBACK_PAGE_V2, ROUTE_EVENT_BOOKING_V2 } from "./utils/constants/global-constants";
const WcpRenewalPage = lazy(()=>import("./pages/wcpRenewalPage")) ;
const SppRenewalPage = lazy(()=>import("./pages/SppRenewalPage")) ;
const PcpRenewalPage = lazy(()=>import("./pages/PcpRenewalPage")) ;
const ReddyParentingUserForm = lazy(()=>import("./pages/ReddyParentingUserForm"));
const UserForm = lazy(() => import("./pages/WcpUserForm"));
const WcpPlans = lazy(() => import("./pages/WcpPlans"));
const StandardPlans = lazy(() => import("./pages/StandardPlans"));
const RootLayout = lazy(() => import("./pages/Root"));
const PaymentSuccessFeedBackPage = lazy(() =>
  import("./pages/PaymentSuccessFeedBackPage")
);
const SlotBookingPage = lazy(() => import("./pages/SlotBooking"));
const SlotBookingFeedBackPage = lazy(() =>
  import("./pages/SlotBookingFeedBackPage")
);
const Workshop = lazy(() => import("./pages/Workshop"));
const SpUserForm = lazy(() => import("./pages/SpUserForm"));
const SuperParentingForm = lazy(() => import("./pages/SuperParentingForm"));
const PrescriptionPage = lazy(() => import("./pages/PrescriptionPage"));
const CalendlyReportPage = lazy(() => import("./pages/CalendlyReportPage"));
const EventBookingPage = lazy(() => import("./pages/EventBookingPage"));
const EventBookingPageV2 = lazy(() => import("./pages/EventBookingPageV2"));
const EventBookingFeedbackPage = lazy(() =>
  import("./pages/EventBookingFeedbackPage")
);
const EventBookingFeedbackPageV2 = lazy(() =>
  import("./pages/EventBookingFeedbackPageV2")
);
const FertilityBookingPage = lazy(() => import("./pages/FertilityBookingPage"));
const ReddyPregnancyUserForm = lazy(() =>
  import("./pages/ReddyPregnancyUserForm")
);

function App() {
  const LoadingElement = <HourglassComponent />;
  //  (
  //   <p style={{ textAlign: "center", paddingTop: "50vh" }}>Loading...</p>
  // );

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Suspense fallback={LoadingElement}>
          <RootLayout />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={LoadingElement}>
              <UserForm />
            </Suspense>
          ),
        },
        {
          path: "superParenting",
          loader: () =>
            import("./pages/SuperParentingForm").then((module) =>
              module.loader()
            ),
          element: (
            <Suspense fallback={LoadingElement}>
              <SuperParentingForm />
            </Suspense>
          ),
          query: {
            plan_duration: "3m",
          },
        },
        {
          path: "wcpRenewal",
          loader: (meta) =>
            import("./pages/wcpRenewalPage").then((module) =>
              module.loader(meta)
            ),
          element: (
            <Suspense fallback={LoadingElement}>
              <WcpRenewalPage />
            </Suspense>
          ),
          query: {
            userId: "dfa236asfe4523af746",
          },
        },
        {
          path: "sppRenewal",
          loader: (meta) =>
            import("./pages/SppRenewalPage").then((module) =>
              module.loader(meta)
            ),
          element: (
            <Suspense fallback={LoadingElement}>
              <SppRenewalPage />
            </Suspense>
          ),
          query: {
            userId: "dfa236asfe4523af746",
          },
        },
        {
          path: "pcpRenewal",
          loader: (meta) =>
            import("./pages/PcpRenewalPage").then((module) =>
              module.loader(meta)
            ),
          element: (
            <Suspense fallback={LoadingElement}>
              <PcpRenewalPage />
            </Suspense>
          ),
          query: {
            userId: "dfa236asfe4523af746",
          },
        },
        {
          path: "drReddyPregnancy",
          element: (
            <Suspense fallback={LoadingElement}>
              <ReddyPregnancyUserForm />
            </Suspense>
          ),
          query: {
            plan_duration: "3m",
          },
        },
        {
          path: "drReddyParenting",
          element: (
            <Suspense fallback={LoadingElement}>
              <ReddyParentingUserForm />
            </Suspense>
          ),
          query: {
            plan_duration: "3m",
          },
        },
        {
          path: "standard",
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={LoadingElement}>
                  <SpUserForm />
                </Suspense>
              ),
            },
            {
              path: "plans",
              id: "std-program-plans",
              loader: (meta) =>
                import("./pages/StandardPlans").then((module) =>
                  module.loader(meta)
                ),
              element: (
                <Suspense fallback={LoadingElement}>
                  <StandardPlans />
                </Suspense>
              ),
              query: {
                lmp: "2023-07-19",
              },
            },
          ],
        },
        {
          path: "plans",
          id: "program-plans",
          loader: (meta) =>
            import("./pages/WcpPlans").then((module) => module.loader(meta)),
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={LoadingElement}>
                  <WcpPlans />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "workshop",
          element: <Workshop />,
          loader: () =>
            import("./pages/Workshop").then((module) => module.loader()),
        },
        {
          path: "success-feedback",
          element: (
            <Suspense fallback={LoadingElement}>
              <PaymentSuccessFeedBackPage />
            </Suspense>
          ),
        },
        {
          path: "slot-booking",
          element: (
            <Suspense fallback={LoadingElement}>
              <SlotBookingPage />
            </Suspense>
          ),
          loader: () =>
            import("./pages/SlotBooking").then((module) => module.loader()),
        },
        {
          path: "SlotBookingFeedBackPage",
          element: (
            <Suspense fallback={LoadingElement}>
              <SlotBookingFeedBackPage />
            </Suspense>
          ),
        },
        {
          path: "prescription",
          element: (
            <Suspense fallback={LoadingElement}>
              <PrescriptionPage />
            </Suspense>
          ),
          loader: () =>
            import("./pages/PrescriptionPage").then((module) =>
              module.loader()
            ),
        },
        {
          path: "calendlyReport",
          element: (
            <Suspense fallback={LoadingElement}>
              <CalendlyReportPage />
            </Suspense>
          ),
          action: ({ request, params }) =>
            import("./pages/CalendlyReportPage").then((module) =>
              module.action({ request, params })
            ),
        },
        {
          path: "eventBooking",
          children: [
            {
              index: true,
              element: <EventBookingPage />,
              loader: (meta) =>
                import("./pages/EventBookingPage").then((module) =>
                  module.loader(meta)
                ),
            },
          ],
        },
        {
          path: "eventBookingFeedback",
          element: (
            <Suspense fallback={LoadingElement}>
              <EventBookingFeedbackPage />,
            </Suspense>
          ),
          loader: (meta) =>
            import("./pages/EventBookingFeedbackPage").then((module) =>
              module.loader(meta)
            ),
        },
        {
          path: ROUTE_EVENT_BOOKING_V2,
          children: [
            {
              index: true,
              element: <EventBookingPageV2 />,
              loader: (meta) =>
                import("./pages/EventBookingPageV2").then((module) =>
                  module.loader(meta)
                ),
            },
          ],
        },
        {
          path: ROUTE_EVENT_BOOKING_FEEDBACK_PAGE_V2,
          element: (
            <Suspense fallback={LoadingElement}>
              <EventBookingFeedbackPageV2 />,
            </Suspense>
          ),
          loader: (meta) =>
            import("./pages/EventBookingFeedbackPageV2").then((module) =>
              module.loader(meta)
            ),
        },
        {
          path: "fertilityBooking",
          element: (
            <Suspense fallback={LoadingElement}>
              <FertilityBookingPage />
            </Suspense>
          ),
          action: ({ request, params }) =>
            import("./pages/FertilityBookingPage").then((module) =>
              module.action({ request, params })
            ),
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
